import React from "react";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

// markup
const IndexPage = () => {
  return (
    <>
      <Seo pathname="/" />
      <Layout title="Home">
        <Hero />
      </Layout>
    </>
  );
};

export default IndexPage;
